.model-page__stage-header {
  padding: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-shrink: 0;
  margin: 25px 0 0 0; }
  .model-page__stage-header--reporting {
    background: #ff7580; }
  .model-page__stage-header--analyzing {
    background: #ffc37e; }
  .model-page__stage-header--optimizing {
    background: #54ce98; }
  .model-page__stage-header--empowering {
    background: #6cb5ed; }
  .model-page__stage-header--innovating {
    background: #9d92ef; }

.model-page__stage-content {
  border: 2px solid transparent;
  padding: 10px 20px;
  flex-grow: 1;
  display: flex;
  align-items: center; }
  .model-page__stage-content--reporting {
    border-color: #ff7580; }
  .model-page__stage-content--analyzing {
    border-color: #ffc37e; }
  .model-page__stage-content--optimizing {
    border-color: #54ce98; }
  .model-page__stage-content--empowering {
    border-color: #6cb5ed; }
  .model-page__stage-content--innovating {
    border-color: #9d92ef; }

.model-page__dimension-header {
  margin: 50px 0 0 0; }

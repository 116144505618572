.app {
  min-height: 100%;
  display: flex;
  flex-direction: column; }
  .app__main {
    line-height: 1.41rem;
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    margin: 10px auto 50px auto;
    flex-grow: 1; }
  .app__footer {
    width: 100%;
    background: #2390e4;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center; }

.assessment {
  position: relative; }
  .assessment__menu {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0; }
  .assessment__menu-item {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    flex-grow: 1;
    justify-content: center;
    padding: 5px; }
    .assessment__menu-item--past {
      opacity: 0.5; }
    .assessment__menu-item--current {
      text-decoration: underline; }
  .assessment__progress {
    height: 20px;
    background: #6cb5ed;
    border-radius: 2px;
    transition: all 0.4s;
    margin: 10px 0; }
  .assessment__dimension {
    font-weight: bold;
    text-align: right; }

.scalar {
  display: flex;
  flex-direction: column;
  background: #ffc7cb;
  padding: 10px;
  border-radius: 2px;
  margin: 5px 0; }
  .scalar__title {
    font-weight: bold; }
  .scalar__scale {
    display: flex;
    justify-content: space-between;
    font-size: 14px; }
  .scalar__options {
    display: flex;
    margin: 10px 0 0 0;
    justify-content: space-between; }
  .scalar__option {
    padding: 5px;
    font-size: 14px;
    flex: 1 0 0;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #ffeff1;
    border-radius: 2px;
    border: 1px solid transparent; }
    .scalar__option:hover {
      background: #ffffff;
      cursor: pointer; }
    .scalar__option--selected {
      background: #ffffff;
      border-color: #444444; }
  .scalar.--unanswered {
    box-shadow: 0 0 10px red; }

.report {
  margin-top: 20px; }
  .report--submitted .no-show {
    display: none; }
  .report__info {
    font-size: 18px; }
  .report__title {
    line-height: 40px;
    font-size: 32px;
    margin-top: 20px; }
  .report__meta {
    display: flex;
    margin: 20px 0;
    width: 600px;
    flex-direction: column;
    flex-wrap: wrap; }
  .report__meta-row {
    display: flex; }
    .report__meta-row div {
      min-width: 200px; }
  .report__model {
    margin-top: -200px; }
  .report__overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0; }
  .report__chart {
    flex-basis: 500px;
    margin: 20px; }
  .report__overview-score {
    flex-grow: 1; }
  .report__buttons {
    display: flex;
    justify-content: space-between; }
  .report__link {
    font-size: 14px; }
  .report__download {
    font-size: 18px;
    height: 80px;
    margin: 20px 0; }
    .report__download button {
      padding: 10px 20px; }
  .report__overview-dimension {
    display: flex;
    flex-direction: column;
    margin: 10px 0; }
  .report__overview-dimension-progress {
    height: 10px;
    background: #000;
    border-radius: 2px;
    margin-bottom: 5px; }
    .report__overview-dimension-progress--level-0 {
      background: #ff7580; }
    .report__overview-dimension-progress--level-1 {
      background: #ff7580; }
    .report__overview-dimension-progress--level-2 {
      background: #ffc37e; }
    .report__overview-dimension-progress--level-3 {
      background: #54ce98; }
    .report__overview-dimension-progress--level-4 {
      background: #6cb5ed; }
    .report__overview-dimension-progress--level-5 {
      background: #9d92ef; }
  .report__reading-guide {
    page-break-inside: avoid; }
  .report__dimension {
    margin: 40px 0 10px 0;
    page-break-inside: avoid; }
  .report__dimension-header {
    display: flex;
    margin: 10px 0;
    align-items: center; }
  .report__dimension-name {
    font-weight: bold; }
  .report__dimension-stage {
    flex-grow: 1;
    text-align: right; }
  .report__dimension-score {
    font-size: 24px;
    flex-grow: 1;
    text-align: right; }
  .report__dimension-progress {
    height: 20px;
    border-radius: 2px;
    background: #9d92ef;
    margin: 5px 0; }
    .report__dimension-progress--level-1 {
      background: #ff7580; }
    .report__dimension-progress--level-2 {
      background: #ffc37e; }
    .report__dimension-progress--level-3 {
      background: #54ce98; }
    .report__dimension-progress--level-4 {
      background: #6cb5ed; }
    .report__dimension-progress--level-5 {
      background: #9d92ef; }
  .report__action-list {
    list-style-type: disc;
    margin: 20px; }
  .report__action-item {
    margin: 0 5px; }

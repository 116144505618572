.model__stage {
  font-family: 'Computer Modern Sans';
  font-size: 24px; }
  .model__stage--faded {
    opacity: 0.2; }

.model__dimension {
  font-family: 'Computer Modern Sans';
  font-size: 22px; }

.model__stage-rect {
  shape-rendering: crispEdges; }
  .model__stage-rect--reporting {
    fill: #ff7580; }
  .model__stage-rect--analyzing {
    fill: #ffc37e; }
  .model__stage-rect--optimizing {
    fill: #54ce98; }
  .model__stage-rect--empowering {
    fill: #6cb5ed; }
  .model__stage-rect--innovating {
    fill: #9d92ef; }

.model__icon {
  opacity: 0.25; }
  .model__icon--chart {
    transform: translate(-29px, 269px) scale(0.35); }
  .model__icon--magnify {
    transform: translate(180px, 270px) scale(0.7); }
  .model__icon--gear {
    transform: translate(330px, 240px) scale(0.3); }
  .model__icon--fist {
    transform: translate(480px, 200px) scale(7); }
  .model__icon--bulb {
    transform: translate(646px, 134px) scale(0.35); }
  .model__icon path {
    fill: #fff; }

.model__stage-name {
  font-size: 22px;
  font-family: 'Merriweather', sans-serif; }

.input {
  margin: 10px 0;
  display: flex; }
  .input--meta {
    background: #ffc7cb;
    padding: 10px;
    border-radius: 2px; }
  .input__description {
    padding: 10px;
    flex-grow: 1;
    text-align: right;
    flex-basis: 60%; }
  .input__field {
    flex-basis: 40%;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid; }

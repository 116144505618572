.introduction {
  position: relative; }
  .introduction__intro {
    margin: 20px 0; }
  .introduction__button {
    margin: 20px auto;
    padding: 30px 60px;
    box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.6); }
  .introduction__name {
    font-size: 32px; }
  .introduction__usps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .introduction__usp {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 33%;
    min-width: 200px;
    margin: 20px 0;
    padding: 0 5px; }
  .introduction__usp-title {
    margin: 10px 0;
    font-size: 32px;
    text-align: center; }
  .introduction__usp-text {
    text-align: center; }
  .introduction__stages {
    display: flex; }
  .introduction__stage {
    margin: 10px 0;
    display: flex; }
  .introduction__title {
    min-height: 68px;
    width: 100%;
    font-size: 32px;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    margin: 25px 0 10px 0; }
    .introduction__title h2 {
      padding: 20px; }
  .introduction__header {
    text-align: center;
    margin-bottom: 30px; }
  .introduction__section {
    margin-top: 50px; }
  .introduction__section-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .introduction__section-columns--reverse {
      flex-direction: row-reverse; }
  .introduction__section-img {
    flex-basis: 380px;
    max-width: 380px;
    display: flex;
    justify-content: center; }
  .introduction__copy {
    flex-basis: 380px;
    max-width: 380px;
    flex-grow: 1; }
  .introduction__dimensions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch; }
  .introduction__dimension {
    flex-basis: 50%;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column; }
  .introduction__dimension-header {
    color: #ffffff;
    font-size: 22px;
    padding: 10px; }
    .introduction__dimension-header--data {
      background: #ff7580; }
    .introduction__dimension-header--metrics {
      background: #ff7580; }
    .introduction__dimension-header--skills {
      background: #ffc37e; }
    .introduction__dimension-header--technology {
      background: #ffc37e; }
    .introduction__dimension-header--leadership {
      background: #54ce98; }
    .introduction__dimension-header--culture {
      background: #54ce98; }
    .introduction__dimension-header--strategy {
      background: #6cb5ed; }
    .introduction__dimension-header--agility {
      background: #6cb5ed; }
    .introduction__dimension-header--integration {
      background: #9d92ef; }
    .introduction__dimension-header--empowerment {
      background: #9d92ef; }
  .introduction__dimension-content {
    padding: 10px;
    flex-grow: 1; }
    .introduction__dimension-content--data {
      border-left: 2px solid #ff7580;
      border-right: 1px solid #ff7580;
      border-bottom: 2px solid #ff7580; }
    .introduction__dimension-content--metrics {
      border-left: 1px solid #ff7580;
      border-right: 2px solid #ff7580;
      border-bottom: 2px solid #ff7580; }
    .introduction__dimension-content--skills {
      border-left: 2px solid #ffc37e;
      border-right: 1px solid #ffc37e;
      border-bottom: 2px solid #ffc37e; }
    .introduction__dimension-content--technology {
      border-left: 1px solid #ffc37e;
      border-right: 2px solid #ffc37e;
      border-bottom: 2px solid #ffc37e; }
    .introduction__dimension-content--leadership {
      border-left: 2px solid #54ce98;
      border-right: 1px solid #54ce98;
      border-bottom: 2px solid #54ce98; }
    .introduction__dimension-content--culture {
      border-left: 1px solid #54ce98;
      border-right: 2px solid #54ce98;
      border-bottom: 2px solid #54ce98; }
    .introduction__dimension-content--strategy {
      border-left: 2px solid #6cb5ed;
      border-right: 1px solid #6cb5ed;
      border-bottom: 2px solid #6cb5ed; }
    .introduction__dimension-content--agility {
      border-left: 1px solid #6cb5ed;
      border-right: 2px solid #6cb5ed;
      border-bottom: 2px solid #6cb5ed; }
    .introduction__dimension-content--integration {
      border-left: 2px solid #9d92ef;
      border-right: 1px solid #9d92ef;
      border-bottom: 2px solid #9d92ef; }
    .introduction__dimension-content--empowerment {
      border-left: 1px solid #9d92ef;
      border-right: 2px solid #9d92ef;
      border-bottom: 2px solid #9d92ef; }

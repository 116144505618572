.likert {
  display: flex;
  flex-direction: column;
  background: #ffc7cb;
  padding: 10px;
  border-radius: 4px;
  margin: 5px 0; }
  .likert__title {
    font-weight: bold; }
  .likert__options {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0 0;
    justify-content: space-between;
    align-items: stretch;
    font-size: 14px; }
  .likert__option {
    padding: 5px;
    display: flex;
    flex: 1 0 50px;
    height: 70px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #ffeff1;
    border-radius: 4px;
    border: 1px solid transparent; }
    .likert__option:hover {
      background: #ffffff;
      cursor: pointer; }
    .likert__option--selected {
      background: #ffffff;
      border-color: #444444; }
  .likert.--unanswered {
    box-shadow: 0 0 10px #ff7580; }

.stage-page__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.stage-page__nav-item {
  padding: 10px;
  flex-basis: 20%;
  text-align: center; }
  .stage-page__nav-item--active {
    border-bottom: 2px solid #6cb5ed; }

.stage-page__action-list {
  list-style-type: disc; }

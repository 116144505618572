@import url(https://fonts.googleapis.com/css?family=Merriweather:300,700&display=swap);
.assessment {
  position: relative; }
  .assessment__menu {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0; }
  .assessment__menu-item {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    flex-grow: 1;
    justify-content: center;
    padding: 5px; }
    .assessment__menu-item--past {
      opacity: 0.5; }
    .assessment__menu-item--current {
      text-decoration: underline; }
  .assessment__progress {
    height: 20px;
    background: #6cb5ed;
    border-radius: 2px;
    transition: all 0.4s;
    margin: 10px 0; }
  .assessment__dimension {
    font-weight: bold;
    text-align: right; }


.button {
  background: #2390e4;
  color: #ffffff;
  padding: 30px 60px;
  border-radius: 4px;
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.6);
  transition: all 0.2s;
  font-family: 'Merriweather', sans-serif;
  outline: none;
  cursor: pointer;
  min-width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none; }
  .button:hover {
    box-shadow: none;
    border: none;
    margin-top: -2px;
    margin-bottom: 2px;
    color: #ffffff; }
  .button:visited {
    color: #ffffff; }
  .button--block {
    display: flex;
    width: 100%; }
  .button__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto 50px auto; }

.model__stage {
  font-family: 'Computer Modern Sans';
  font-size: 24px; }
  .model__stage--faded {
    opacity: 0.2; }

.model__dimension {
  font-family: 'Computer Modern Sans';
  font-size: 22px; }

.model__stage-rect {
  shape-rendering: crispEdges; }
  .model__stage-rect--reporting {
    fill: #ff7580; }
  .model__stage-rect--analyzing {
    fill: #ffc37e; }
  .model__stage-rect--optimizing {
    fill: #54ce98; }
  .model__stage-rect--empowering {
    fill: #6cb5ed; }
  .model__stage-rect--innovating {
    fill: #9d92ef; }

.model__icon {
  opacity: 0.25; }
  .model__icon--chart {
    transform: translate(-29px, 269px) scale(0.35); }
  .model__icon--magnify {
    transform: translate(180px, 270px) scale(0.7); }
  .model__icon--gear {
    transform: translate(330px, 240px) scale(0.3); }
  .model__icon--fist {
    transform: translate(480px, 200px) scale(7); }
  .model__icon--bulb {
    transform: translate(646px, 134px) scale(0.35); }
  .model__icon path {
    fill: #fff; }

.model__stage-name {
  font-size: 22px;
  font-family: 'Merriweather', sans-serif; }

.input {
  margin: 10px 0;
  display: flex; }
  .input--meta {
    background: #ffc7cb;
    padding: 10px;
    border-radius: 2px; }
  .input__description {
    padding: 10px;
    flex-grow: 1;
    text-align: right;
    flex-basis: 60%; }
  .input__field {
    flex-basis: 40%;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid; }

.scalar {
  display: flex;
  flex-direction: column;
  background: #ffc7cb;
  padding: 10px;
  border-radius: 2px;
  margin: 5px 0; }
  .scalar__title {
    font-weight: bold; }
  .scalar__scale {
    display: flex;
    justify-content: space-between;
    font-size: 14px; }
  .scalar__options {
    display: flex;
    margin: 10px 0 0 0;
    justify-content: space-between; }
  .scalar__option {
    padding: 5px;
    font-size: 14px;
    flex: 1 0;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #ffeff1;
    border-radius: 2px;
    border: 1px solid transparent; }
    .scalar__option:hover {
      background: #ffffff;
      cursor: pointer; }
    .scalar__option--selected {
      background: #ffffff;
      border-color: #444444; }
  .scalar.--unanswered {
    box-shadow: 0 0 10px red; }

.likert {
  display: flex;
  flex-direction: column;
  background: #ffc7cb;
  padding: 10px;
  border-radius: 4px;
  margin: 5px 0; }
  .likert__title {
    font-weight: bold; }
  .likert__options {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0 0;
    justify-content: space-between;
    align-items: stretch;
    font-size: 14px; }
  .likert__option {
    padding: 5px;
    display: flex;
    flex: 1 0 50px;
    height: 70px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #ffeff1;
    border-radius: 4px;
    border: 1px solid transparent; }
    .likert__option:hover {
      background: #ffffff;
      cursor: pointer; }
    .likert__option--selected {
      background: #ffffff;
      border-color: #444444; }
  .likert.--unanswered {
    box-shadow: 0 0 10px #ff7580; }

.dimension {
  transition: all 0.5s;
  opacity: 0; }
  .dimension--entering {
    opacity: 0; }
  .dimension--entered {
    opacity: 1; }
  .dimension__name {
    text-align: right;
    padding: 10px 0;
    font-size: 32px; }
  .dimension__description {
    border-bottom: 1px solid; }
  .dimension__section {
    border-bottom: 1px solid;
    padding: 10px 0 20px 0; }

.maturity-question {
  transition: all 0.3s;
  width: 100%;
  margin: 10px 0;
  background: #d9ecfa;
  border-radius: 4px; }
  .maturity-question__title {
    padding: 10px;
    font-weight: bold; }
  .maturity-question__info {
    font-weight: normal;
    font-size: 14px; }
  .maturity-question__option {
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    padding: 5px 0;
    border-left: 4px solid transparent; }
    .maturity-question__option--highlighted {
      border-left: 4px solid #6cb5ed; }
    .maturity-question__option--answered {
      border-left: 4px solid #6cb5ed;
      background: #b5daf6; }
    .maturity-question__option:hover {
      text-decoration: underline; }
  .maturity-question__index {
    flex-basis: 50px;
    padding-left: 20px;
    flex-shrink: 0; }
  .maturity-question.--unanswered {
    box-shadow: 0 0 10px red; }

.instructions__research {
  background: #ffc7cb;
  padding: 20px;
  border-radius: 2px;
  margin: 20px 0; }

.stages__stage {
  margin: 50px 0; }

.stages__stage-description {
  margin: 10px 0; }

.report {
  margin-top: 20px; }
  .report--submitted .no-show {
    display: none; }
  .report__info {
    font-size: 18px; }
  .report__title {
    line-height: 40px;
    font-size: 32px;
    margin-top: 20px; }
  .report__meta {
    display: flex;
    margin: 20px 0;
    width: 600px;
    flex-direction: column;
    flex-wrap: wrap; }
  .report__meta-row {
    display: flex; }
    .report__meta-row div {
      min-width: 200px; }
  .report__model {
    margin-top: -200px; }
  .report__overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0; }
  .report__chart {
    flex-basis: 500px;
    margin: 20px; }
  .report__overview-score {
    flex-grow: 1; }
  .report__buttons {
    display: flex;
    justify-content: space-between; }
  .report__link {
    font-size: 14px; }
  .report__download {
    font-size: 18px;
    height: 80px;
    margin: 20px 0; }
    .report__download button {
      padding: 10px 20px; }
  .report__overview-dimension {
    display: flex;
    flex-direction: column;
    margin: 10px 0; }
  .report__overview-dimension-progress {
    height: 10px;
    background: #000;
    border-radius: 2px;
    margin-bottom: 5px; }
    .report__overview-dimension-progress--level-0 {
      background: #ff7580; }
    .report__overview-dimension-progress--level-1 {
      background: #ff7580; }
    .report__overview-dimension-progress--level-2 {
      background: #ffc37e; }
    .report__overview-dimension-progress--level-3 {
      background: #54ce98; }
    .report__overview-dimension-progress--level-4 {
      background: #6cb5ed; }
    .report__overview-dimension-progress--level-5 {
      background: #9d92ef; }
  .report__reading-guide {
    page-break-inside: avoid; }
  .report__dimension {
    margin: 40px 0 10px 0;
    page-break-inside: avoid; }
  .report__dimension-header {
    display: flex;
    margin: 10px 0;
    align-items: center; }
  .report__dimension-name {
    font-weight: bold; }
  .report__dimension-stage {
    flex-grow: 1;
    text-align: right; }
  .report__dimension-score {
    font-size: 24px;
    flex-grow: 1;
    text-align: right; }
  .report__dimension-progress {
    height: 20px;
    border-radius: 2px;
    background: #9d92ef;
    margin: 5px 0; }
    .report__dimension-progress--level-1 {
      background: #ff7580; }
    .report__dimension-progress--level-2 {
      background: #ffc37e; }
    .report__dimension-progress--level-3 {
      background: #54ce98; }
    .report__dimension-progress--level-4 {
      background: #6cb5ed; }
    .report__dimension-progress--level-5 {
      background: #9d92ef; }
  .report__action-list {
    list-style-type: disc;
    margin: 20px; }
  .report__action-item {
    margin: 0 5px; }


.introduction {
  position: relative; }
  .introduction__intro {
    margin: 20px 0; }
  .introduction__button {
    margin: 20px auto;
    padding: 30px 60px;
    box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.6); }
  .introduction__name {
    font-size: 32px; }
  .introduction__usps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .introduction__usp {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 33%;
    min-width: 200px;
    margin: 20px 0;
    padding: 0 5px; }
  .introduction__usp-title {
    margin: 10px 0;
    font-size: 32px;
    text-align: center; }
  .introduction__usp-text {
    text-align: center; }
  .introduction__stages {
    display: flex; }
  .introduction__stage {
    margin: 10px 0;
    display: flex; }
  .introduction__title {
    min-height: 68px;
    width: 100%;
    font-size: 32px;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    margin: 25px 0 10px 0; }
    .introduction__title h2 {
      padding: 20px; }
  .introduction__header {
    text-align: center;
    margin-bottom: 30px; }
  .introduction__section {
    margin-top: 50px; }
  .introduction__section-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .introduction__section-columns--reverse {
      flex-direction: row-reverse; }
  .introduction__section-img {
    flex-basis: 380px;
    max-width: 380px;
    display: flex;
    justify-content: center; }
  .introduction__copy {
    flex-basis: 380px;
    max-width: 380px;
    flex-grow: 1; }
  .introduction__dimensions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch; }
  .introduction__dimension {
    flex-basis: 50%;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column; }
  .introduction__dimension-header {
    color: #ffffff;
    font-size: 22px;
    padding: 10px; }
    .introduction__dimension-header--data {
      background: #ff7580; }
    .introduction__dimension-header--metrics {
      background: #ff7580; }
    .introduction__dimension-header--skills {
      background: #ffc37e; }
    .introduction__dimension-header--technology {
      background: #ffc37e; }
    .introduction__dimension-header--leadership {
      background: #54ce98; }
    .introduction__dimension-header--culture {
      background: #54ce98; }
    .introduction__dimension-header--strategy {
      background: #6cb5ed; }
    .introduction__dimension-header--agility {
      background: #6cb5ed; }
    .introduction__dimension-header--integration {
      background: #9d92ef; }
    .introduction__dimension-header--empowerment {
      background: #9d92ef; }
  .introduction__dimension-content {
    padding: 10px;
    flex-grow: 1; }
    .introduction__dimension-content--data {
      border-left: 2px solid #ff7580;
      border-right: 1px solid #ff7580;
      border-bottom: 2px solid #ff7580; }
    .introduction__dimension-content--metrics {
      border-left: 1px solid #ff7580;
      border-right: 2px solid #ff7580;
      border-bottom: 2px solid #ff7580; }
    .introduction__dimension-content--skills {
      border-left: 2px solid #ffc37e;
      border-right: 1px solid #ffc37e;
      border-bottom: 2px solid #ffc37e; }
    .introduction__dimension-content--technology {
      border-left: 1px solid #ffc37e;
      border-right: 2px solid #ffc37e;
      border-bottom: 2px solid #ffc37e; }
    .introduction__dimension-content--leadership {
      border-left: 2px solid #54ce98;
      border-right: 1px solid #54ce98;
      border-bottom: 2px solid #54ce98; }
    .introduction__dimension-content--culture {
      border-left: 1px solid #54ce98;
      border-right: 2px solid #54ce98;
      border-bottom: 2px solid #54ce98; }
    .introduction__dimension-content--strategy {
      border-left: 2px solid #6cb5ed;
      border-right: 1px solid #6cb5ed;
      border-bottom: 2px solid #6cb5ed; }
    .introduction__dimension-content--agility {
      border-left: 1px solid #6cb5ed;
      border-right: 2px solid #6cb5ed;
      border-bottom: 2px solid #6cb5ed; }
    .introduction__dimension-content--integration {
      border-left: 2px solid #9d92ef;
      border-right: 1px solid #9d92ef;
      border-bottom: 2px solid #9d92ef; }
    .introduction__dimension-content--empowerment {
      border-left: 1px solid #9d92ef;
      border-right: 2px solid #9d92ef;
      border-bottom: 2px solid #9d92ef; }

.model-page__stage-header {
  padding: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 50px;
  flex-shrink: 0;
  margin: 25px 0 0 0; }
  .model-page__stage-header--reporting {
    background: #ff7580; }
  .model-page__stage-header--analyzing {
    background: #ffc37e; }
  .model-page__stage-header--optimizing {
    background: #54ce98; }
  .model-page__stage-header--empowering {
    background: #6cb5ed; }
  .model-page__stage-header--innovating {
    background: #9d92ef; }

.model-page__stage-content {
  border: 2px solid transparent;
  padding: 10px 20px;
  flex-grow: 1;
  display: flex;
  align-items: center; }
  .model-page__stage-content--reporting {
    border-color: #ff7580; }
  .model-page__stage-content--analyzing {
    border-color: #ffc37e; }
  .model-page__stage-content--optimizing {
    border-color: #54ce98; }
  .model-page__stage-content--empowering {
    border-color: #6cb5ed; }
  .model-page__stage-content--innovating {
    border-color: #9d92ef; }

.model-page__dimension-header {
  margin: 50px 0 0 0; }

.stage-page__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.stage-page__nav-item {
  padding: 10px;
  flex-basis: 20%;
  text-align: center; }
  .stage-page__nav-item--active {
    border-bottom: 2px solid #6cb5ed; }

.stage-page__action-list {
  list-style-type: disc; }

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column; }
  .app__main {
    line-height: 1.41rem;
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    margin: 10px auto 50px auto;
    flex-grow: 1; }
  .app__footer {
    width: 100%;
    background: #2390e4;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center; }

.alert {
  font-size: 24px;
  padding: 20px;
  color: #ffffff;
  line-height: 30px;
  border-radius: 4px;
  margin: 20px auto; }
  .alert--green {
    background: #54ce98; }

.brand {
  color: #2390e4; }

.header {
  display: flex;
  justify-content: space-between;
  background: #2390e4;
  color: #ffffff;
  padding: 5px 25px;
  align-items: center; }
  .header__title {
    margin: 10px 0;
    cursor: pointer;
    font-size: 28px;
    line-height: 28px;
    color: #ffffff; }
    .header__title:visited {
      color: #ffffff; }
    .header__title:hover {
      color: #ff7580;
      border-bottom: 2px solid transparent; }
  .header__nav {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-between; }
  .header__nav-item {
    text-transform: uppercase;
    font-size: 16px;
    color: #ffffff; }
    .header__nav-item:visited {
      color: #ffffff; }
    .header__nav-item:hover {
      color: #ff7580;
      border-bottom: 2px solid transparent; }

@media only screen and (max-width: 600px) {
  .header {
    padding: 10px; }
    .header__title {
      font-size: 24px;
      line-height: 24px; } }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a:visited {
  color: inherit; }

/* latin */
@font-face {
  font-family: 'Cambo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Cambo"), local("Cambo-Regular"), url(https://fonts.gstatic.com/s/cambo/v8/IFSqHeNEk8FJk719qE8.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 40px;
  margin: 20px 0; }
  h1--bottom-margin {
    margin-bottom: 20px; }

h2 {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 30px; }

.h2--margin {
  margin-top: 50px; }

h3 {
  font-size: 22px;
  font-weight: 300; }

body {
  font-family: 'Merriweather', sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444; }

@media print {
  .no-print {
    display: none; }
  #drift-widget-container {
    display: none; } }

p {
  margin: 10px 0; }

a {
  text-decoration: none;
  color: #2390e4;
  border: 2px solid transparent; }
  a:visited {
    color: #2390e4; }
  a:hover {
    border-bottom: 2px solid #6cb5ed; }

html {
  height: 100%; }

b {
  font-weight: bold; }

body {
  width: 100%;
  height: 100%;
  margin: 0 auto; }

#root {
  height: 100%; }

.dict {
  color: #2390e4;
  border-bottom: 1px dashed;
  cursor: pointer; }
  .dict:hover .dict__definition {
    display: block;
    position: fixed;
    top: 20px;
    background: #9d92ef;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    text-align: center; }
  .dict__definition {
    display: none; }

.fiveten {
  display: block;
  font-family: 'Cambo', serif;
  border: 1px solid;
  padding: 40px;
  border-radius: 2px;
  font-size: 2.25rem;
  line-height: 0.9em;
  color: #000000 !important;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  background: #d9d5f9;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); }
  @media (min-width: 768px) {
    .fiveten {
      font-size: 4rem; } }
  .fiveten__introducing {
    font-size: 1.5rem;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .fiveten__introducing {
        font-size: 2.25rem; } }
  .fiveten__brand {
    color: #7566e8; }
  .fiveten__tagline {
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    background: #ffffff;
    color: #000000;
    border-radius: 4px;
    line-height: 1em;
    display: inline-flex;
    padding: 10px 20px;
    letter-spacing: 0.05em;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    border: none;
    margin-top: 20px; }
    .fiveten__tagline:visited {
      color: #000000; }
    .fiveten__tagline:hover {
      background: #eeeeee;
      border: none; }


.dimension {
  transition: all 0.5s;
  opacity: 0; }
  .dimension--entering {
    opacity: 0; }
  .dimension--entered {
    opacity: 1; }
  .dimension__name {
    text-align: right;
    padding: 10px 0;
    font-size: 32px; }
  .dimension__description {
    border-bottom: 1px solid; }
  .dimension__section {
    border-bottom: 1px solid;
    padding: 10px 0 20px 0; }

.fiveten {
  display: block;
  font-family: 'Cambo', serif;
  border: 1px solid;
  padding: 40px;
  border-radius: 2px;
  font-size: 2.25rem;
  line-height: 0.9em;
  color: #000000 !important;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  background: #d9d5f9;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25); }
  @media (min-width: 768px) {
    .fiveten {
      font-size: 4rem; } }
  .fiveten__introducing {
    font-size: 1.5rem;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .fiveten__introducing {
        font-size: 2.25rem; } }
  .fiveten__brand {
    color: #7566e8; }
  .fiveten__tagline {
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    background: #ffffff;
    color: #000000;
    border-radius: 4px;
    line-height: 1em;
    display: inline-flex;
    padding: 10px 20px;
    letter-spacing: 0.05em;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    border: none;
    margin-top: 20px; }
    .fiveten__tagline:visited {
      color: #000000; }
    .fiveten__tagline:hover {
      background: #eeeeee;
      border: none; }

@import url("https://fonts.googleapis.com/css?family=Merriweather:300,700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a:visited {
  color: inherit; }

/* latin */
@font-face {
  font-family: 'Cambo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Cambo"), local("Cambo-Regular"), url(https://fonts.gstatic.com/s/cambo/v8/IFSqHeNEk8FJk719qE8.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 40px;
  margin: 20px 0; }
  h1--bottom-margin {
    margin-bottom: 20px; }

h2 {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 30px; }

.h2--margin {
  margin-top: 50px; }

h3 {
  font-size: 22px;
  font-weight: 300; }

body {
  font-family: 'Merriweather', sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444; }

@media print {
  .no-print {
    display: none; }
  #drift-widget-container {
    display: none; } }

p {
  margin: 10px 0; }

a {
  text-decoration: none;
  color: #2390e4;
  border: 2px solid transparent; }
  a:visited {
    color: #2390e4; }
  a:hover {
    border-bottom: 2px solid #6cb5ed; }

html {
  height: 100%; }

b {
  font-weight: bold; }

body {
  width: 100%;
  height: 100%;
  margin: 0 auto; }

#root {
  height: 100%; }

.dict {
  color: #2390e4;
  border-bottom: 1px dashed;
  cursor: pointer; }
  .dict:hover .dict__definition {
    display: block;
    position: fixed;
    top: 20px;
    background: #9d92ef;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
    text-align: center; }
  .dict__definition {
    display: none; }

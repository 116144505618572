.maturity-question {
  transition: all 0.3s;
  width: 100%;
  margin: 10px 0;
  background: #d9ecfa;
  border-radius: 4px; }
  .maturity-question__title {
    padding: 10px;
    font-weight: bold; }
  .maturity-question__info {
    font-weight: normal;
    font-size: 14px; }
  .maturity-question__option {
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    padding: 5px 0;
    border-left: 4px solid transparent; }
    .maturity-question__option--highlighted {
      border-left: 4px solid #6cb5ed; }
    .maturity-question__option--answered {
      border-left: 4px solid #6cb5ed;
      background: #b5daf6; }
    .maturity-question__option:hover {
      text-decoration: underline; }
  .maturity-question__index {
    flex-basis: 50px;
    padding-left: 20px;
    flex-shrink: 0; }
  .maturity-question.--unanswered {
    box-shadow: 0 0 10px red; }

.button {
  background: #2390e4;
  color: #ffffff;
  padding: 30px 60px;
  border-radius: 4px;
  box-shadow: 0 10px 50px -10px rgba(0, 0, 0, 0.6);
  transition: all 0.2s;
  font-family: 'Merriweather', sans-serif;
  outline: none;
  cursor: pointer;
  min-width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none; }
  .button:hover {
    box-shadow: none;
    border: none;
    margin-top: -2px;
    margin-bottom: 2px;
    color: #ffffff; }
  .button:visited {
    color: #ffffff; }
  .button--block {
    display: flex;
    width: 100%; }
  .button__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto 50px auto; }

.header {
  display: flex;
  justify-content: space-between;
  background: #2390e4;
  color: #ffffff;
  padding: 5px 25px;
  align-items: center; }
  .header__title {
    margin: 10px 0;
    cursor: pointer;
    font-size: 28px;
    line-height: 28px;
    color: #ffffff; }
    .header__title:visited {
      color: #ffffff; }
    .header__title:hover {
      color: #ff7580;
      border-bottom: 2px solid transparent; }
  .header__nav {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-between; }
  .header__nav-item {
    text-transform: uppercase;
    font-size: 16px;
    color: #ffffff; }
    .header__nav-item:visited {
      color: #ffffff; }
    .header__nav-item:hover {
      color: #ff7580;
      border-bottom: 2px solid transparent; }

@media only screen and (max-width: 600px) {
  .header {
    padding: 10px; }
    .header__title {
      font-size: 24px;
      line-height: 24px; } }
